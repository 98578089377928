import React from 'react';
import Layout from '@components/Layout/LayoutTemplates/JobSeekersLayout';
import Section from '@components/Section';
import Topbar from '@components/Topbar';
import ResultSearch from '@components/CalendarSearchSection/ResultSearch';
import EventCardsViewer from '@components/ArticleCardsViewer/EventCardsViewer';
import NoResultEmptyState from '@components/NoResultEmptyState';
import { filterEventsContents } from '@helpers/methods/filterContents';
import {
  useQueryParams,
  withDefault,
  StringParam,
  ArrayParam,
} from 'use-query-params';

const DEFAULT_URL_EVENTS_QUERY = {
  query: withDefault(StringParam, ''),
  search: withDefault(ArrayParam, []),
  start: withDefault(StringParam, ''),
  author: withDefault(StringParam, ''),
};
const ResultsViewer = ({ data }) => {
  const { eventCardItems, flexSearchOptions, searchSuggestions } = data;
  const [filters, setFilters] = useQueryParams(DEFAULT_URL_EVENTS_QUERY);

  const handleSearchQuery = (response) => {
    const { results, query, start } = response;
    const isQueryInvalid =
      typeof query !== 'string' && typeof start !== 'string';
    if (isQueryInvalid) return;
    const hasSameQueryAsBefore =
      filters.query === query && filters.start === start;
    if (hasSameQueryAsBefore) return;
    const sanitizeQuery = (queryString) => {
      const isQueryEmptyString = queryString === '';
      return isQueryEmptyString ? undefined : queryString;
    };

    setFilters({
      search: results,
      query: sanitizeQuery(query),
      start: sanitizeQuery(start),
    });
  };

  const visibleEventContents = filterEventsContents(eventCardItems, filters);
  return (
    <Layout className={'events-results-container'} isMobileComingSoon={false}>
      <ResultSearch
        onQuery={handleSearchQuery}
        header="Events from Wan PNG community"
        searchPlaceholder="Search from our events"
        flexSearchOptions={flexSearchOptions}
        defaultValue={filters.query}
        searchSuggestions={searchSuggestions}
        datePickerDefaultValue={filters.start}
      />
      <Section>
        <Topbar
          count={visibleEventContents?.length}
          defaultValue={filters.sort}
        />
      </Section>

      <Section className="events-results-items">
        <EventCardsViewer
          EmptyState={<NoResultEmptyState />}
          contents={visibleEventContents}
          linkRoot={'/events/'}
        />
      </Section>
    </Layout>
  );
};

export default ResultsViewer;
