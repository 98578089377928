import isEmpty from 'lodash/isEmpty';
import { useStaticQuery, graphql } from 'gatsby';
import useFlexSuggestionIndex from '@helpers/hooks/useFlexSuggestionIndex';
import getEventCardsProps from '@helpers/methods/modifyContents/getEventCardsProps';

const Fetcher = ({ children }) => {
  const {
    allStrapiEvents,
    flexSuggestionOptions,
    flexSearchOptions,
  } = useStaticQuery(
    graphql`
      {
        allStrapiEvents(
          filter: { published_at: { ne: null }, is_archived: { ne: true } }
        ) {
          nodes {
            id
            creator_id
            created_by {
              username
            }
            author {
              username
            }
            slug

            thumbnail_image {
              source_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, quality: 100)
                  }
                }
              }
              cropped_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, quality: 100)
                  }
                }
              }
            }
            thumbnail_image_old: thumbnail {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, quality: 100)
                }
              }
            }

            title
            content
            strapiId
            created_at(formatString: "MMMM DD, YYYY")
            published_date
            published_at
            start_date
            start_time
            end_date
            end_time
            location {
              province {
                name
              }
              type
            }

            is_virtual_career_fair
          }
        }
        flexSearchOptions: localSearchEvents {
          index
          store
        }

        flexSuggestionOptions: localSearchEventsSuggestions {
          index
        }
      }
    `
  );

  const eventCardItems = getEventCardsProps(allStrapiEvents?.nodes);
  const hasNoEvents = isEmpty(allStrapiEvents?.nodes);

  const suggestedWordsIndex = useFlexSuggestionIndex(
    flexSuggestionOptions?.index
  );
  const suggestedTitlesIndex = eventCardItems.map(({ title }) => title);
  const searchSuggestions = [...suggestedWordsIndex, ...suggestedTitlesIndex];

  return children({
    eventCardItems,
    hasNoEvents,
    flexSearchOptions,
    searchSuggestions,
  });
};

export default Fetcher;
