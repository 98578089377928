import React from 'react';
import CalendarSearchBar from '@components/CalendarSearchBar';
import Section from '@components/Section';

const DEFAULT_HEADER = 'What do you want to learn?';

const ResultSearch = ({
  header = DEFAULT_HEADER,
  searchPlaceholder = '',
  defaultValue = '',
  onQuery,
  flexSearchOptions,
  searchSuggestions,
  datePickerDefaultValue = '',
}) => {
  return (
    <Section className="search-section" color="ash">
      <div className="search-result">
        <div className="search-result-banner-container">
          <h1 className="search-result-header">{header}</h1>
        </div>
        <CalendarSearchBar
          searchPlaceholder={searchPlaceholder}
          defaultValue={defaultValue}
          flexSearchOptions={flexSearchOptions}
          searchSuggestions={searchSuggestions}
          onQuery={onQuery}
          page="result"
          datePickerDefaultValue={datePickerDefaultValue}
        />
      </div>
    </Section>
  );
};

export default ResultSearch;
